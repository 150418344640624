.search {
    .day-block {
        float: left;
        width: 50%;
        padding: 8px 0;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        text-align: center;
        position: relative;

        .today-btn {
            margin-bottom: 10px;

            a {
                padding: 2px 12px;
                border-radius: 10px;
                color: #71a8bc;
                font-weight: 500;
                border: 1px solid #b3bab3;

                &.active {
                    text-decoration: none;
                    background: #71a8bc;
                    color: #fff;
                }

                &:hover {
                    text-decoration: none;
                }
            }
        }

        .tomorrow-btn {
            margin-bottom: 10px;

            a {
                padding: 2px 16px;
                border-radius: 10px;
                color: #71a8bc;
                font-weight: 500;
                border: 1px solid #b3bab3;

                &.active {
                    text-decoration: none;
                    background: #71a8bc;
                    color: #fff;
                }

                &:hover {
                    text-decoration: none;
                }
            }
        }
    }

    .popular-list {
        position: absolute;
        top: 68px;
        left: 4px;
        z-index: 11;
        width: 400px;

        .list-group {
            margin: 0;
            padding: 0;

            li {
                position: relative;
                font-weight: 400;
                color: #808380;
                cursor: pointer;
                list-style: none;

                &:before {
                    background: url(../images/search/bus-ok.svg) no-repeat;
                    position: absolute;
                    background-size: contain;
                    content: "";
                    width: 14px;
                    height: 10px;
                    left: 10px;
                    bottom: 13px;
                }

                &.active {
                    background: #77abbf;
                    color: #fff;
                    border-color: silver;

                    &:before {
                        background: url(../images/search/bus-ok-white.svg) no-repeat;
                        position: absolute;
                        background-size: contain;
                        content: "";
                        width: 14px;
                        height: 10px;
                        left: 10px;
                        bottom: 13px;
                    }
                }

                &:hover {
                    background: #77abbf;
                    color: #fff;

                    &:before {
                        background: url(../images/search/bus-ok-white.svg) no-repeat;
                        position: absolute;
                        background-size: contain;
                        content: "";
                        width: 14px;
                        height: 10px;
                        left: 10px;
                        bottom: 13px;
                    }
                }
            }

            .list-group-item {
                padding: 8px 23px;
                font-weight: 500;
            }
        }
    }

    .voyage {
        position: absolute;
        top: 68px;
        left: 4px;
        z-index: 11;
        width: 400px;

        .airpport-name {
            margin: 0;
            padding: 0;

            .list-group-item {
                border-radius: inherit;

                &.active {
                    border-color: silver;
                }
            }

            li {
                position: relative;
                font-weight: 400;
                color: #808380;
                cursor: pointer;
                /*&:first-child {
                    background: #77abbf;
                    color: #fff;
                }*/
                &:before {
                    background: url(../images/search/bus-ok.svg) no-repeat;
                    position: absolute;
                    background-size: contain;
                    content: "";
                    width: 14px;
                    height: 10px;
                    left: 10px;
                    bottom: 13px;
                }

                &.active {
                    background: #77abbf;
                    color: #fff;
                    border-color: silver;

                    &:before {
                        background: url(../images/search/bus-ok-white.svg) no-repeat;
                        position: absolute;
                        background-size: contain;
                        content: "";
                        width: 14px;
                        height: 10px;
                        left: 10px;
                        bottom: 13px;
                    }
                }

                &:hover {
                    background: #77abbf;
                    color: #fff;

                    &:before {
                        background: url(../images/search/bus-ok-white.svg) no-repeat;
                        position: absolute;
                        background-size: contain;
                        content: "";
                        width: 14px;
                        height: 10px;
                        left: 10px;
                        bottom: 13px;
                    }
                }
            }

            .list-group-item {
                padding: 8px 23px;
                font-weight: 500;
            }
        }
    }

    .datepicker {
        .flatpickr-calendar open {
            left: -37px !important;

            &:before {
                left: 120px !important;
            }
        }
    }
}

@media screen and (max-width: 1200px) {
    .search {
        .up-button {
            margin: 5px 0px;

            &:after {
                top: 11px;
            }
        }
        .swap-btn-mobile{
            display: none;
        }       
    }
}

@media screen and (max-width: 1024px) {
    .search {
        .voyage {
            width: 400px;
        }

        .swap-btn-mobile {
            display: none;
        }
    }
}

@media screen and (max-width: 767px) {
    .search {
        .bus-input {
            margin-top: 8px;
        }

        .bus-datepicker {
            margin-top: 8px;
        }
        .voyage {
            width: 100%;
        }
    }
}

@media screen and (max-width: 480px) {
    .search {
        .up-button {
            &:after {
                top: 6px;
            }
        }

        .datepicker {
            margin-top: 8px;

            .date {
                .plus {
                    &:before {
                        left: 85px;
                    }
                }
            }
        }

        .btn {
            height: 50px;
        }
    }
}

@media screen and (max-width: 320px) {
    .search {
        .datepicker {
            .date {
                .plus {
                    &:before {
                        left: 62px;
                    }
                }
            }
        }
    }
}
