body {
    background: url(../images/main-background.jpg) center top #fff no-repeat;
    background-size: contain;
    overflow-x: hidden;
    padding: 0;
    margin: 0;
    font-weight: 400;
    font-family: 'Quicksand', sans-serif;
}

body.mobilAppOn {
    background-position: top 70px center;
}

@media screen and (max-width: 768px) {
    body {
        background-size: initial;
    }
}