﻿.search-engine {
    .big-bus-title {
        margin-top: 50px;

        h1 {
            font-size: 35px;
            font-weight: 500;
            color: #225979;
            text-align: center;
            margin: 0;
            padding: 0;
        }
    }

    .search-types-bus {
        margin: 30px 5px;
        position: relative;

        .nav-tabs > li > a {
            border: 0;
        }

        .nav {
            margin: 0;
            padding: 0;
            display: inline-block;
            border-radius: 3px;
            box-shadow: 0 0 4px 0 rgba(21,99,140,0.33);

            > li {
                margin-bottom: 0px;
                border-right: 1px solid #e4f6ff;
                background: #fff;

                &:last-child {
                    border: none;
                }

                > a {
                    padding: 35px 20px 5px;
                    font-size: 16px;
                    font-weight: 500;
                    text-align: center;
                    color: #7cb4d2;
                    margin-right: 0px;
                    border-right: 0;


                    &:active, &:focus, &:hover {
                        text-decoration: none;
                        background-color: #e4f6ff !important;
                        outline: 0;
                        box-shadow: 0px;
                    }
                } 

                & a:before {
                    display: inline-block;
                    content: "";
                    position: absolute;
                    top: 11px;
                    width: 100%;
                    height: 22px;
                    background-position: center center;
                    background-repeat: no-repeat;
                    z-index: 8;
                    left:0;
                }

                &.icon-flight a::before {
                    background-image: url(../images/search-menu/ucak-mavi.svg);
                }

                &.icon-bus a::before {
                    background-image: url(../images/search-menu/otobus-mavi.svg);
                }

                &.icon-hotel a::before {
                    background-image: url(../images/search-menu/otel-mavi.svg);
                }

                &.icon-ferry a::before {
                    background-image: url(../images/search-menu/feribot-mavi.svg);
                }

                &.active {
                    > a {
                        color: #fff;
                        background-color: #7cb4d2;
                        border: 0px;
                        border-radius: 0;
                        box-shadow: 0 0 4px 0 rgba(21,99,140,0.33);

                        &:hover, &:focus {
                            color: #fff;
                            background-color: #7cb4d2 !important;
                        }
                         
                        &:before {
                            /*width: 0;
                            height: 0;
                            border-left: 10px solid transparent;
                            border-right: 10px solid transparent;
                            border-top: 10px solid #7cb4d2;
                            position: absolute;
                            bottom: -10px;
                            left: 50%;
                            margin-left: -8px;
                            content: "";
                            display: inline-block;*/
                        }
                    }

                    &.icon-flight a::before {
                        background-image: url(../images/search-menu/ucak-beyaz.svg);
                    }

                    &.icon-bus a::before {
                        background-image: url(../images/search-menu/otobus-beyaz.svg);
                    }

                    &.icon-hotel a::before {
                        background-image: url(../images/search-menu/otel-beyaz.svg);
                    }

                    &.icon-ferry a::before {
                        background-image: url(../images/search-menu/feribot-beyaz.svg);
                    }

                }
            }
        }
    }
}
.cheap-bus {
    position: relative;

    .title-center {
        margin-top: 30px;
        font-size: 25px;
        font-weight: 500;
        text-align: center; 
        color: #808380;
    } 

    .cheap-title {  margin-top:70px; margin-bottom:40px;
        h2 {
            color: #225979;
        }
    }

    .domestic-title {
        text-align: left;
    }

    .abroad-title {
        text-align: right;
    }

    ul {
        margin: 0;
        padding: 0;
        list-style: none;
        position: relative;

        li {
            border-radius: 5px;
            box-shadow: 0 0 4px 0 rgba(21, 99, 140, 0.33);
            margin-top: 20px;
            position: relative;
            cursor: pointer;

            &.left {

                &:before {
                    position: absolute;
                    width: 16px;
                    height: 16px;
                    border-radius: 16px;
                    content: '';
                    display: inline-block;
                    background: #71a8bc;
                    z-index: 2;
                    left: -40px;
                    top: 23px;
                }

                &:after {
                    position: absolute;
                    width: 1px;
                    height: 75px;
                    content: '';
                    display: inline-block;
                    border-right: 1px dashed #71a8bc;
                    left: -32px;
                    top: 30px;
                }
            }

            &.right {

                &:before {
                    position: absolute;
                    width: 16px;
                    height: 16px;
                    border-radius: 16px;
                    content: '';
                    display: inline-block;
                    background: #71a8bc;
                    z-index: 2;
                    right: -40px;
                    top: 23px;
                }

                &:after {
                    position: absolute;
                    width: 1px;
                    height: 75px;
                    content: '';
                    display: inline-block;
                    border-right: 1px dashed #71a8bc;
                    right: -32px;
                    top: 30px;
                }
            }
        }
    }

    ul:last-child > li:after {
        display: none;
    }

    .box {
        padding: 10px 0px;

        .price {
            font-size: 25px;
            font-weight: 500;
            text-align: left;
            color: #71a8bc;

            &:before {
                background: url(/images/popular/lira.svg) no-repeat;
                display: inline-block;
                width: 13px;
                height: 19px;
                left: -2px;
                top: 9px;
                content: "";
                position: absolute;
            }

            &:after {
                position: absolute;
                background: url(../images/bus/home/navy-blue.png) no-repeat;
                display: inline-block;
                background-size: contain;
                width: 21px;
                height: 21px;
                right: 27px;
                top: 10px;
                content: "";
            }

            span {
                font-size: 14px;
            }
        }

        .departure {
            font-size: 18px;
            font-weight: 500;
            text-align: right;
            margin-top: 5px;
            color: #808380;
        }

        .line {
            position: relative;

            &:after {
                display: inline-block;
                width: 29px;
                height: 10px;
                left: 7px;
                top: 13px;
                content: "";
                position: absolute;
                background: url(../images/suggest/sag-ok-liste.svg) no-repeat;
            }
        }

        .arrival {
            font-size: 18px;
            font-weight: 500;
            margin-top: 5px;
            color: #808380;
        }

        .brand {
            font-size: 12px;
            font-weight: 500;
            color: #808380;

            img {
                margin-left: -6px;
            }
        }
    }
}
.popular-list-bus {

    .domestic-list {
        margin-top: 23px;
        position: relative;

        ul {
            list-style: none;
            margin: 0;
            padding: 0;

            li {
                border-radius: 5px;
                box-shadow: 0 0 4px 0 rgba(21, 99, 140, 0.33);
                margin-top: 20px;

                &.bottom {
                    position: relative;

                    &:before {
                        position: absolute;
                        width: 1px;
                        height: 19px;
                        content: '';
                        display: inline-block;
                        border-right: 1px dashed #979797;
                        left: 50%;
                        top: 64px;
                    }
                }

                .city {
                    text-align: center;

                    .text {
                        display: inline-block;
                        font-size: 18px;
                        font-weight: 500;
                        color: #808380;
                        margin-top: 20px;
                    }
                }

                .img {
                    width: 200px;
                    text-align: right;
                }
            }

            li:last-child:before {
                display: none;
            }
        }
    }
}
.comment {
    margin-bottom: 50px;
}

.search-engine .search-types-bus .nav > li.active > a:after {
    content: "";
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 10px solid #7cb4d2;
    position: absolute;
    bottom: -10px;
    left: 50%;
    margin-left: -8px;
    content: "";
    display: inline-block;
}

@media screen and (max-width: 1024px){
    section{position:relative;}
    .search-engine {
        position: relative;
     
         
        .big-bus-title {
            margin-top: 20px;  

            h1 {
                font-size: 32px;
            }
        }

        .search-types-bus {
            margin: 15px 5px;

            .nav {
                > {
                    li {
                        > {
                            a {
                                font-size: 14px;
                            }
                        }
                    }
                }

                .onoffswitch {
                    right: 5px;
                }
            }
        }
    }
}
@media screen and (max-width: 768px) {
    .search-engine { z-index: 11;

        .big-bus-title {
            h1 {
                font-size: 22px;
            }
        }
    }
    .cheap-bus {
        .abroad-title {
            text-align: left;
        }

        ul {
            li {
                margin-top: 6px;

                &.left {
                    &:before {
                        left: -22px;
                    }

                    &:after {
                        left: -15px;
                    }
                }

                &.right {
                    &:before {
                        left: -22px;
                    }

                    &:after {
                        left: -15px;
                    }
                }
            }
        }
    }
    .abroad-bus {
        .boxes {
            .box {
                .img {
                    height: 148px;
                }
            }
        }
    }
}
@media screen and (max-width: 480px) {
    .search-engine {
        .big-bus-title {
            margin-top: 10px;

            h1 {
                font-size: 18px;
            }
        }

        .search-types-bus {
            margin: 14px 0 9px 5px;
        }
    }

    .cheap-bus {
        .title-center {
            font-size: 18px;
            margin-top: 10px;
            text-align: left;
        }

        ul {
            li {
                &.left {
                    &:after {
                        height: 60px;
                    }
                }

                &.right {
                    &:after {
                        height: 60px;
                    }
                }
            }
        }

        .box {
            .price {
                &:after {
                    width: 16px;
                    height: 16px;
                    right: 8px;
                }
            }
        }
    }

    .abroad-bus {
        .icon {
            margin-top: 50px;
        }

        .abroad-title {
            h2 {
                font-size: 30px;
            }
        }

        .boxes {
            .box {
                .img {
                    height: 200px;
                }
            }
        }
    }
}
@media screen and (max-width: 375px) {
    .search-engine {

        .search-types-bus {
            .nav > li > a {
                font-size: 12px;
            }
        }
    }

    .cheap-bus {
        .box {
            .departure {
                font-size: 16px;
            }

            .arrival {
                font-size: 16px;
            }

            .price {
                font-size: 20px;
                margin-top: 3px;

                &:before {
                    width: 11px;
                    height: 16px;
                    background-size: contain;
                }

                &:after {
                    width: 12px;
                    height: 12px;
                    top: 12px;
                    right: 4px;
                }
            }
        }
    }
}
@media screen and (max-width: 320px){
    .search-engine {
        .big-bus-title {
            h1 {
                font-size: 22px;
            }
        }

        .search-types-bus {
            .nav {
                > {
                    li {
                        > {
                            a {
                                font-size: 10px;
                                padding: 35px 16px 2px;
                            }
                        }
                    }
                }
            }
        }
    }
     
    .cheap-bus {
        .title-center {
            font-size: 14px;
        }

        .box {
            .departure {
                font-size: 14px;
            }

            .arrival {
                font-size: 14px;
            }

            .line {
                &:after {
                    left: 4px;
                }
            }

            .price {
                font-size: 16px;
                margin-top: 5px;

                &:before {
                    width: 11px;
                    height: 13px;
                    left: 6px;
                    top: 10px;
                }
            }
        }
    }
}


.mobile-application-download {
    bottom: 0;
    width: 100%;
    height: 70px;
    background: #fff;
    z-index: 100;
    padding: 0 14px;
    -webkit-box-shadow: -1px -5px 5px 1px rgba(0,0,0,0.29);
    box-shadow: -1px -5px 5px 1px rgba(0,0,0,0.12);
    display: none;
    position:relative;

    .mobile-download-container {
        position: relative;
        z-index:10;

        a {
            display: flex;
            text-decoration:none;


            .mobile-application-logo {
                height: 40px;
                width: 80px;
                background: #2da2cf;
                -webkit-border-radius: 5px;
                border-radius: 5px;
                position: relative;
                margin-top: 15px;
                margin-right: 20px;

                img {
                    max-width: 80%;
                    position: absolute;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    top: 0;
                    margin: auto;
                }
            }

            .text {
                width: 174px;
                float: left;

                h3 {
                    padding: 0;
                    margin-top: 18px;
                    font-size: 14px;
                    margin-bottom: 2px;
                    font-weight: 400;
                    color: #000;
                }
            }

            .description {
                font-weight: 400;
                color: #000;

                span {
                    color: #000;
                    font-weight: 500;
                }
            }
        }



        .close {
            cursor: pointer;
            position: absolute;
            right: 0;
            top: 0;
            bottom: 0;
            line-height: 69px;
            opacity: 1 !important;
            color: #777;
            width: 20px;
            text-align: right;
            cursor: pointer;
            font-size: 19px;
            z-index: 100;
        }
    }
}

@media screen and (min-width: 768px) {
    .mobile-application-download {display:none;}
}

