﻿.airline-custom {
    .interCity.airline {
        .item {
            margin-bottom: 0 !important;
        }

        .text {
            text-align: center;
            z-index: 1000;
            box-shadow: 0 0 65px 0 rgba(0,0,0,0.05);
            background-color: #f2eeee;
            font-size: 13px;
            font-weight: 600;
            padding: 5px 0 !important;
            border-bottom-left-radius: 5px;
            border-bottom-right-radius: 5px;
            margin-bottom: 20px;
            height: 48px;
            display: flex;

            p {
                margin: auto !important;
                color: #555555;
            }
        }
    }
}

.interCity {
    padding: 50px 0 0 0 !important;
    margin: 0 !important;

    .kunye{
        .text{

            background:transparent !important; 
            display:block !important;
            box-shadow:none !important;

            *{width:100% !important;}
        }
    }
    h1, h2 {
        font-size: 23px;
        margin-top: 0 !important;
        margin-bottom: 40px;
        color: #5e5d5d;
        text-align: center;
        font-weight: 500;
    }

    h3 {
        text-align: center;
        width: 100%;
    }

    .row {
        margin-left: -10px;
        margin-right: -10px;
    }

    .col-md-2, .col-md-4, .col-md-9, .col-md-3, .col-md-6, .col-md-8, .col-md-12 {
        padding-left: 10px;
        padding-right: 10px;
    }

    .container {
        padding-left: 10px !important;
        padding-right: 10px !important;
    }



    .image {
        img {
            max-width: 100%;
        }
    }

    .alternative-ticket {
        margin-top: 50px;



        .item {
            background: #fff;
            -webkit-border-radius: 5px;
            border-radius: 5px;
            -webkit-box-shadow: 0 0 65px 0 rgba(0,0,0,0.1);
            box-shadow: 0 0 65px 0 rgba(0,0,0,0.1);
            border: solid 0 #010101;
            min-height: 92px;
            padding: 23px;


            .ticket-name {
                color: #808380;
                max-width: 170px;
                font-weight: 500;
                line-height: 22px;

                img {
                    max-width: 102px;
                    min-height: 41px;
                }
            }

            .ticket-price {
                background: #7cb4d2;
                color: #fff;
                line-height: 37px;
                float: right;
                -webkit-border-radius: 5px;
                border-radius: 5px;
                font-size: 15px;
                font-weight: 600;
                text-align: center;
                padding: 0 6px;
                margin-top: 4px;
            }
        }
    }

    .info {
        margin-top: 40px;

        .row {
            margin-left: -5px;
            margin-right: -5px;
            display: flex;

            .col {
                flex-basis: 0;
                -webkit-box-flex: 1;
                flex-grow: 1;
                max-width: 100%;
                padding-left: 5px;
                padding-right: 5px;
            }
        }

        .col-md-2, .col-md-4, .col-md-9, .col-md-3, .col-md-6, .col-md-8 {
            padding-left: 5px;
            padding-right: 5px;
        }

        .forecast-custom {
            padding-top: 0 !important;
            position: relative;
            padding-top: 0 !important;
        }

        .forecast-table {
            padding-top: 0px;
            background: #1a80a9;


            .title {
                margin-bottom: 6px !important;
                color: #fff;
                height: 23px;
            }

            .price {
                margin-top: 0 !important;
                color: #fff;
                margin-bottom: 15px;
            }

            p {
                text-align: center;
                color: #fff;
                margin-bottom: 1px;
                font-weight: 500;
                font-size: 12px;
            }

            p:last-child {
                margin-top: 10px;
                text-decoration: underline;
            }
        }



        .item {
            -webkit-border-radius: 5px;
            border-radius: 5px;
            -webkit-box-shadow: 0 0 65px 0 rgba(0,0,0,0.1);
            box-shadow: 0 0 65px 0 rgba(0,0,0,0.1);
            border: solid 0 #010101;
            background-color: #ffffff;
            height: 165px;
            padding-top: 48px;
        }

        .icon {
            text-align: center;
            min-height: 42px;
        }

        .title {
            text-align: center;
            font-size: 15px;
            max-width: 170px;
            margin: auto;
            font-weight: 500;
            margin-top: 0;
            height: 41px;
            margin-bottom: 20px;
            color: #a2a3a3;
        }

        .price {
            text-align: center;
            font-size: 30px;
            font-weight: 600;
            color: #19b7de;
            line-height: 24px;
            margin-top: 15px;

            img {
                max-width: 100px;
                margin-top: -12px;
            }
        }
    }

    .result-list {
        margin-top: 50px;

        .row {
            margin-left: -10px;
            margin-right: -10px;
        }

        .col-md-2, .col-md-4, .col-md-9, .col-md-3, .col-md-6, .col-md-8 {
            padding-left: 10px;
            padding-right: 10px;
        }

        h1 {
            text-align: center;
            font-size: 24px;
        }

        li.travelItem {
            float: left;
            width: 50%;
            margin-bottom: 20px;
            border: 0;

            .bus-about {
                text-align: center;
                float: right;
                margin-top: 0 !important;

                > * {
                    display: inline-block;
                }

                .flight-about {
                    display: none;
                }
            }

            .ticket {
                border-radius: 5px;
                box-shadow: 0 0 65px 0 rgba(0,0,0,0.1);
                border: solid 0 #010101;
                background: #ffffff;
                padding: 20px 15px 20px 15px;

                .bus-price {
                    text-align: right;

                    .decimals, .price {
                        font-size: 20px;

                        &:after {
                        }
                    }

                    .price:after {
                        content: ",";
                        font-size: 20px;
                        float: right;
                        margin-top: 0;
                        font-weight: 500;
                        color: #808380;
                    }

                    .decimals {
                        margin-left: -4px;
                    }

                    .price-block {
                        margin-bottom: 10px;
                    }

                    .btn-round {
                        border-radius: 8px;
                        background: #7cb4d2;
                        color: #fff;
                        border: 0;
                        line-height: 25px;
                    }
                }

                .fly-time {
                    padding: 0 !important;

                    .name {
                        height: 30px;
                    }

                    .bus-name, .time {
                        text-align: left;
                        font-weight: 500;
                        font-size: 14px;
                        margin-top: 14px;
                    }

                    .time {
                        color: #19b7de !important;
                    }

                    .date {
                        display: none;
                    }

                    .departure {
                        text-align: right;
                        border-right: 1px dotted #19b7de;

                        .time {
                            color: #19b7de !important;
                            font-size: 26px !important;
                        }
                    }

                    .arrival {
                        .time {
                            color: #19b7de !important;
                            font-size: 26px !important;
                            margin-top: 14px;
                        }
                    }
                }

                .bus-logo {
                    .brand-bus {
                        margin-top: -8px !important;

                        .logo {
                            img {
                                max-height: 40px;
                            }
                        }
                    }
                }

                &:before {
                    opacity: 0;
                }

                .brand-image {
                    .brand-bus {
                        margin-top: 9px !important;
                    }
                }
            }
        }
    }

    .bus-station-list {
        margin-top: 30px;

        .row {
            margin-left: -10px;
            margin-right: -10px;
        }

        .col-md-2, .col-md-4, .col-md-9, .col-md-3, .col-md-6, .col-md-8 {
            padding-left: 10px;
            padding-right: 10px;
        }

        .item {
            box-shadow: 0 0 65px 0 rgba(0, 0, 0, 0.1);
            border: solid 0 #010101;
            background-color: #ffffff;
            border-radius: 5px;
            padding-bottom: 20px;
            padding-top: 20px;

            ul {
                list-style: none;
                padding: 0;
                margin-bottom: 0;

                li {
                    background: #fff;
                    padding-left: 20px;
                    padding-right: 20px;

                    p {
                        margin: 0 !important;
                        line-height: 50px;
                        font-weight: 500;
                        font-size: 18px;
                        color: #a2a3a3;
                        position: relative;
                    }

                    p.phone {
                        text-align: center;
                    }

                    p.name:after {
                        content: "";
                        display: block;
                        clear: both;
                        width: 1px;
                        height: 30px;
                        position: absolute;
                        right: 0;
                        top: 0;
                        bottom: 0;
                        margin: auto;
                        background: #adadad;
                    }
                }

                li:nth-child(odd) {
                    background: #f5f5f5 !important;
                }
            }
        }
    }

    .bus-companies {
        margin-top: 50px;

        .item {
            border-radius: 5px;
            box-shadow: 0 0 65px 0 rgba(0, 0, 0, 0.05);
            border: solid 0 #010101;
            background-color: #ffffff;
            min-height: 165px;
            position: relative;
            margin-bottom: 30px;

            img {
                max-width: 85%;
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                margin: auto;
            }
        }
    }

    .expeditions {
        h3 {
            margin-bottom: 30px;
            color: #5e5d5d;
            font-weight: 600;
            font-size: 26px;
            margin-top: 0 !important;
        }

        .item {
            ul {
                li {
                    .price {
                        background: #7cb4d2;
                        color: #fff !important;
                        line-height: 37px !important;
                        float: right;
                        border-radius: 5px;
                        font-size: 15px;
                        font-weight: 600;
                        text-align: center;
                        padding: 0 16px;
                        margin-top: 7px !important;
                        width: 102px;
                        height: 38px;
                    }
                }
            }
        }


        p.name {
            &:after {
                opacity: 0 !important;
            }
        }

        .description-text {
            .text {
                max-width: 380px;
                text-align: center;
                margin: auto;
                margin-top: 20px;
                color: #a2a3a3;
            }
        }
    }

    .selected-company {
        h2 {
            margin: auto;
            line-height: 30px;
            margin-bottom: 40px;
        }
    }
}


@media screen and (max-width: 1200px) {
    .interCity {
        .alternative-ticket {
            .item {
                margin-bottom: 10px;
                padding: 10px;
                min-height: auto !important;

                .ticket-name {
                }

                .ticket-price {
                    font-size: 13px;
                }
            }
        }
    }
}

@media screen and (max-width: 991px) {

    .interCity {
        .info {
            .row {
                display: block !important;

                .col {
                    width: 33.33% !important;
                    margin-bottom: 10px !important;
                    float: left;
                }
            }

            .item {
                margin-bottom: 10px;
            }
        }

        .result-list {
            .price-block {
                text-align: left;
                font-size: 36px;
                margin: 0 !important;
                margin-top: 9px !important;
                float: left;
            }

            .bus-price {
                .button {
                    margin-top: 20px;
                }
            }

            .decimals, .price {
                font-size: 24px !important;
                float: left;
                vertical-align: top;
                line-height: 20px !important;
                margin-right: 3px;
            }
        }

        .bus-station-list {
            .item {
                p {
                    font-size: 13px !important;
                }
            }
        }
    }
}

@media screen and (max-width: 767px) {

    .interCity {
        padding-top: 0 !important;

        .info {
            .row {
                display: block !important;

                .col {
                    width: 50% !important;
                }
            }
        }

        .alternative-ticket {
            margin-top: 30px !important;
        }


        h1, h2 {
            font-size: 18px !important;
        }

        .result-list {
            margin-top: 25px !important;

            ul {
                li.travelItem {
                    width: 100% !important;

                    .bus-logo {
                        display: block !important;
                    }

                    .name {
                        font-size: 11px !important;
                    }

                    .brand-bus {
                        .logo {
                            text-align: left !important;
                        }

                        &:after {
                            opacity: 0 !important;
                        }
                    }

                    .bus-price {
                        .button {
                            margin-top: 3px !important;
                        }
                    }
                }
            }
        }

        .bus-station-list {
            .item {
                ul {
                    li {
                        .name {
                            text-align: center;
                            line-height: 20px;
                            padding-top: 9px;
                        }
                    }
                }

                p {
                    text-align: center;
                }

                .name {
                    &:after {
                        width: 30px !important;
                        height: 1px !important;
                        bottom: 0;
                        left: 0;
                        top: initial !important;
                        right: 0;
                        margin: auto;
                    }
                }

                .price {
                    float: none !important;
                    max-width: 150px;
                    margin: auto !important;
                    margin-bottom: 10px !important;
                }
            }
        }

        .bus-companies {
            .item {
                min-height: auto !important;
                padding: 0 20px;
                padding-bottom: 60%; 
            }
        }
    }
}

@media screen and (max-width: 480px) {
    .interCity {
        .info {
            .title {
                font-size: 12px;
            }

            .price {
                font-size: 25px;
            }
        }
    }
}
