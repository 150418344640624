footer {
    .footer-brand {
        background: #f8f8f8;
        position: relative;

        .title-brand {
            margin-top: 25px;
            text-align: center;
            max-width: 100%;
            overflow: hidden;

            h2 {
                display: inline-block;
                position: relative;
                text-align: center;
                font-weight: 500;
                font-size: 32px;
                color: #225979;
                margin: 0;
                padding: 0;

                &:after {
                    background: url(/images/icons/bus-brand.svg) no-repeat;
                    position: absolute;
                    background-size: contain;
                    content: "";
                    width: 60px;
                    height: 60px;
                    right: -80px;
                    top: -9px;
                }
            }
        }

        .title-city {
            margin-top: 25px;
            text-align: center;
            overflow: hidden;

            h2 {
                display: inline-block;
                position: relative;
                text-align: center;
                font-weight: 500;
                font-size: 32px;
                color: #225979;
                margin: 0;
                padding: 0;

                &:after {
                    background: url(/images/icons/travel.svg) no-repeat;
                    position: absolute;
                    background-size: contain;
                    content: "";
                    width: 54px;
                    height: 54px;
                    right: -80px;
                    top: -13px;
                }
            }
        }

        .title-destination {
            margin-top: 25px;
            text-align: center;

            h2 {
                display: inline-block;
                position: relative;
                text-align: center;
                font-weight: 500;
                font-size: 32px;
                color: #225979;
                margin: 0;
                padding: 0;
            }
        }

        .list-brand {
            padding: 20px 0;
            position: relative;
            border-bottom: 1px solid #d8d9d8;

            .footer-nav-title {
                font-size: 16px;
                font-weight: 400;
                color: #808380;
                margin-bottom: 3px;
            }

            ul {
                margin: 0;
                padding: 0;
                list-style: none;

                li {
                    font-size: 14px;
                    letter-spacing: -0.2px;
                    color: #808380;
                    font-size: 15px;
                    position: relative;

                    a {
                        color: #808380;
                        font-weight: 400;
                        line-height: 1.5;
                        padding-left: 17px;

                        &:after {
                            content: "";
                            background: #71a8bc;
                            display: block;
                            width: 0.7rem;
                            margin-left: -1em;
                            float: left;
                            height: 0.7rem;
                            -webkit-border-radius: 100%;
                            border-radius: 100%;
                            margin-top: 5px;
                            left: 0;
                            position: absolute;
                            top: 7px;
                            margin: auto;
                        }

                        &:before {
                            position: absolute;
                            width: 1px;
                            height: 20px;
                            content: '';
                            display: inline-block;
                            border-right: 1px dashed #71a8bc;
                            left: 3px;
                            top: 7px;
                        }

                        &:hover {
                            color: #71a8bc;

                            :after {
                                width: 0.8rem;
                                height: 0.8rem;
                            }
                        }

                        &:focus {
                            text-decoration: none;
                        }
                    }
                }

                li:last-child {
                    a {
                        &:before {
                            display: none;
                        }
                    }
                }
            }
        }

        .list-city {
            padding: 20px 0;
            position: relative;
            border-bottom: 1px solid #d8d9d8;

            .footer-nav-title {
                font-size: 16px;
                font-weight: 500;
                color: #808380;
                margin-bottom: 3px;
            }

            ul {
                margin: 0;
                padding: 0;
                list-style: none;

                li {
                    font-size: 14px;
                    letter-spacing: -0.2px;
                    color: #808380;
                    font-size: 15px;


                    a {
                        color: #808380;
                        font-weight: 400;
                        line-height: 1.5;
                        position: relative;
                        padding-left: 17px;

                        &:before {
                            position: absolute;
                            width: 1px;
                            height: 20px;
                            content: '';
                            display: inline-block;
                            border-right: 1px dashed #71a8bc;
                            left: 3px;
                            top: 7px;
                        }


                        &:after {
                            content: "";
                            background: #71a8bc;
                            display: block;
                            width: 0.7rem;
                            margin-left: -1em;
                            float: left;
                            height: 0.7rem;
                            -webkit-border-radius: 100%;
                            border-radius: 100%;
                            margin-top: 5px;
                            left: 0;
                            position: absolute;
                            top: 7px;
                            margin: auto;
                        }

                        &:hover {
                            color: #71a8bc;
                        }

                        &:focus {
                            text-decoration: none;
                        }
                    }
                }

                li:last-child {
                    a {
                        &:before {
                            display: none !important;
                        }
                    }
                }
            }
        }

        .list-destination {
            padding: 20px 0;
            position: relative;

            .footer-nav-title {
                font-size: 16px;
                font-weight: 400;
                color: #808380;
                margin-bottom: 3px;
            }

            ul {
                margin: 0;
                padding: 0;
                list-style: none;

                li {
                    font-size: 14px;
                    letter-spacing: -0.2px;
                    color: #808380;
                    font-size: 15px;

                    a {
                        color: #808380;
                        font-weight: 500;
                        line-height: 1.5;

                        &:hover {
                            color: #71a8bc;
                        }

                        &:focus {
                            text-decoration: none;
                        }
                    }
                }
            }
        }
    }

    .footer-nav {
        margin-top: 0;

        .logo {
            margin-top: 15px;
            text-align: left;

            img {
                max-width: 120px;
            }
        }

        .association {
            text-align: right;
            margin-top: 10px;


            img {
                margin-right: 7px;
            }
        }

        .social-media {
            text-align: left;
            margin-top: 0;

            ul {
                margin-top: 15px;

                li {
                    img {
                        width: 33px;
                        margin-bottom: 0;
                    }
                }
            }
        }
    }
}

.bus-footer {
    .footer-nav {
        .copyright {
            border: 0 !important;
            line-height: initial;
            margin-top: 7px !important;
        }
    }

    .logo {
        a {
            display: block;

            img {
                margin-bottom: 10px !important;
            }
        }
    }

    .copyright {
        clear: both;
        margin-bottom: 20px;
    }
}

@media only screen and (max-width: 1024px) and (min-width: 767px) {

    footer {
        .footer-brand {
            .list-brand, .list-city {
                ul {
                    li {
                        a {
                            font-size: 11px;
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 767px) {
    footer .footer-brand .list-brand ul li a
    footer {
        .list-city {
            .popular-city {
                ul {
                    li {
                        width: 50%;
                        float: left;
                        margin-bottom: 10px;
                    }
                }
            }
        }

        .footer-nav {
            .logo {
                img {
                    width: inherit;
                }
            }

            .copyright {
                margin-top: 12px;
            }
        }

        .footer-brand {
            .title-city {
                overflow: hidden;
            }

            .title-brand, .title-city {
                h2 {
                    font-size: 21px;
                    display: block;
                    text-align: left;

                    &:after {
                        background: none !important;
                    }
                }
            }


            .list-brand {
                text-align: center;

                ul {
                    li {
                        font-size: 14px;
                        margin-bottom: 10px;

                        a {
                            display: block;
                            text-align: left;
                            font-size: 11px;
                            position: relative;
                            padding-left: 15px;

                            &:after {
                                content: "";
                                background: #71a8bc;
                                display: block;
                                width: 0.5rem;
                                margin-left: -1em;
                                float: left;
                                height: 0.5rem;
                                border-radius: 100%;
                                margin-top: 5px;
                                left: 1px;
                                position: absolute;
                                top: 0;
                                bottom: 0;
                                margin: auto;
                            }
                        }
                    }
                }



                .col-sm-3.col-xs-6:nth-child(2n+3) {
                    clear: both;
                }
            }

            .list-city {
                .popular-city {
                    text-align: center;
                }

                ul {
                    li {
                        font-size: 14px;
                        margin-bottom: 6px;

                        a {
                            display: block;
                            text-align: left;
                            font-size: 11px;
                            position: relative;
                            padding-left: 10px;

                            &:after {
                                content: "";
                                background: #71a8bc;
                                display: block;
                                width: 0.5rem;
                                margin-left: 1px;
                                float: left;
                                height: 0.5rem;
                                -webkit-border-radius: 100%;
                                border-radius: 100%;
                                margin-top: -1px;
                            }
                        }
                    }
                }
            }
        }

        .footer-popular {
            h2 {
                padding-left: 15px !important;
            }
        }
    }
}

@media screen and (max-width: 640px) {
    footer {
        .footer-nav {
            .logo {
                text-align: center;
                background: #ebebeb;
                padding: 15px 0;
            }

            .social-media {
                text-align: center;
            }

            .association {
                display: inline-block;
            }
        }
    }
}

@media screen and (max-width: 480px) {
    footer {


        .footer-nav {
            .social-media {
                ul {
                    li {
                        img {
                            width: 40px;
                        }
                    }
                }
            }
        }

        .footer-brand {
            a {
                font-size: 11px;
            }

            .list-brand {
                .col-sm-3 {
                    margin-bottom: 20px;
                }

                .col-sm-3:nth-child(2n+3) {
                    clear: both;
                }
            }
        }


        .list-city {
            a {
                font-size: 11px;
            }

            .col-sm-3 {
                margin-bottom: 20px;
            }

            .col-sm-3:nth-child(2n+3) {
                clear: both;
            }
        }
    }
}


